<template>
    <div class="card ">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <h1>Our Services</h1>
                    <p>Alor Feri is a promising Bangladeshi technology startup founded in 2021. The company's mission is to
                        empower libraries and connect readers with book.</p>

                    <ul class="list-group">
                        <li class="list-group-item">Software Development</li>
                        <li class="list-group-item">Training & Consultancy</li>
                        <li class="list-group-item">Software as a Service (SaaS)</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <img class="img-fluid rounded" src="@/assets/images/our_services/ios_seminar_at_bracu.png" alt="Office Environment">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.custom-section {
            margin-top: 20px;
            margin-bottom: 20px;
            border-radius: 20px;
            background-color: #fef8f8;

            padding: 20px;
        }

        .list-group-item {
            background-color: #fff;
        }
</style>

<script>


export default {
    name: "OurServices",
    components: {},
};
</script>
