<template>
    <div class="">
        <h2>Advertisers (Upcoming) </h2>

        <ul>
            <li>
                Job posting
            </li>

            <li>
                Resume search
            </li>
            <li>
                Applicant tracking.
            </li>
        </ul>

        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
export default {
    name: "Advertisers",
    components: {},
    mounted: function() {},
    computed: {},
    props: {},
    data() {
        return {};
    },
    methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
