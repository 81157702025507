<template>
    <div class="d-flex justify-content-center pt-3">
        <div class="spinner-border  text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Loading",
    data() {
        return {};
    },
    computed: {},
  async mounted(){},
    methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
