<template>
    <div class="text-center">

        <iframe src="https://backoffice.alorferi.com/privacy-policy/alorferi"
        width="800"
        height="500"
        > </iframe>

    </div>
</template>

<script>
export default {
    name: "PrivacyPolicy",
    components: {},
    mounted: function() {},
    computed: {

    },
    props: {},
    data() {
        return {

        };
    },
    methods:{

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
