<template>
    <div class=" border-left border-right ">
        <div class="jumbotron text-center" style="margin-bottom:0">
            <h1 class="text-center">Contact Us</h1>
            <h4>We are here for you</h4>
        </div>

        <div class="p-4">
            <h5>
                Address
            </h5>
            <div>
                437/1 A North Kazipara, Kafrul, Dhaka
            </div>
        </div>

        <div class="p-4">
            <h5>
                Mobile
            </h5>
            <div>
                Call us:  <a href="tel:+8801717983473">+880 1717 983 473</a>,  <a href="tel:+88017406671998">+880 1740 6671 998</a>

            </div>
        </div>

        <div class="p-4">
            <h5>
                E-mail
            </h5>
            <div>
                Email us:  <a href = "mailto:info@alorferi.com">info@alorferi.com</a>
            </div>
        </div>
        <div class="p-4">
            <h5>
                Facebook
            </h5>
            <div>
                Send us a direct message from our
                <a href="https://www.facebook.com/alorferi" target="_blank"
                    >Alor Feri Facebook Page</a
                >
            </div>
        </div>







    </div>
</template>

<script>
export default {
    name: "ContactUs",
    components: {},
    mounted: function() {},
    computed: {},
    props: {},
    data() {
        return {};
    },
    methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
